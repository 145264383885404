import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useFormikContext } from 'formik';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => {
  return {
    root: {
      color: props => props.color || theme.palette.primary.main,
      border: props => `1px solid ${props.borderColor || props.color || theme.palette.primary.main}`,
      backgroundColor: props => props.backgroundColor || '#fff',
      boxShadow: '0 2px 4px 0 rgba(145, 145, 145, 0.5)',
      width: props => props.width || '150px',
      borderRadius: props => props.borderRadius || '4px',
      marginTop: props => props.mt || 0,
      marginBottom: props => props.mb || 0,
      marginLeft: props => props.ml || 0,
      marginRight: props => props.mr || 0,
      order: props => props.order,
    },
  };
});

const EryButton = props => {
  const classes = useStyles(props);
  const formikBag = useFormikContext();

  return (
    <Button
      className={classes.root}
      onClick={() => props.onClick && props.onClick()}
      disabled={props.disabled || (formikBag && formikBag.isSubmitting)}
      tabIndex={props.tabIndex}
      type={props.type}
    >
      {props.label || props.children}
    </Button>
  );
};

EryButton.propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
};

export default EryButton;

export const TestEryButton = EryButton;
