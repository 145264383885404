import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { useErySettings } from '../ErySettings';

const styles = theme => {
  return {
    root: {
      fontFamily: 'Philosopher, Arial',
      fontSize: '5em',
      textDecoration: 'none',
      textDecorationColor: 'none',
    },

    logoDot: {
      color: theme.palette.text.secondary,
      fontSize: '.75em',
      letterSpacing: '-.05em',
    },
    logoEry: {
      color: theme.palette.text.primary,
    },
    logoProduct: {
      color: theme.palette.text.product,
    },
  };
};

const EryLogo = ({ classes, ...props }) => {
  const erySettings = useErySettings();
  let product = props.product || erySettings.productPhonemes;

  if (product && !Array.isArray(product)) {
    product = Array(product);
  }
  return (
    <Typography className={classes.root} variant='h1'>
      {product.map((phoneme, i) => (
        <React.Fragment key={i}>
          <span className={classes.logoProduct}>{phoneme}</span>
          <span className={classes.logoDot}>·</span>
        </React.Fragment>
      ))}
      <span className={classes.logoEry}>ery</span>
    </Typography>
  );
};

EryLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(EryLogo);
