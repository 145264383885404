import React from 'react';

import { useFormikContext } from 'formik';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { Form, Formik } from 'formik';

import EryButton from '../EryButton';
import EryButtons from '../EryButtons';
import EryFormField from '../EryFormField';
import EryFormFieldGroup from '../EryFormFieldGroup';
import ErySubmitButton from '../ErySubmitButton';

const EryFormInner = props => {
  const { submitForm } = useFormikContext();

  const handleKeyPress = (e: Object) => {
    if (e.key === 'Escape' && props.onPressEscapeKey) {
      props.onPressEscapeKey();
    }

    if (e.key === 'Enter' && e.ctrlKey && props.onPressCtrlEnterKey) {
      props.onPressCtrlEnterKey() || submitForm();
    }
  };

  return <Box onKeyDown={handleKeyPress}>{props.children}</Box>;
};

const EryForm = (props: Object) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await props.onSubmit(values);
        setSubmitting(false);
      }}
      validate={values => props.validate && props.validate(values)}
    >
      <Form>
        <EryFormInner {...props} />
      </Form>
    </Formik>
  );
};

export default EryForm;
