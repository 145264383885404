import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import EryButton from '../EryButton';
import EryButtons from '../EryButtons';
import EryDialog from '../EryDialog';
import EryForm from '../EryForm';
import EryFormField from '../EryFormField';
import EryFormFieldGroup from '../EryFormFieldGroup';
import EryInsetInput from '../EryInsetInput';
import EryLoadingSpinner from '../EryLoadingSpinner';
import ErySubmitButton from '../ErySubmitButton';

import SocialMediaButtons from './SocialMediaButtons';
import { Auth0Context } from './index';

const styles = {
  divider: {
    margin: '2em',
  },
};

const LoginDialog = ({ classes, maximize, onClose, open }) => {
  const { loginWithCredentials } = useContext(Auth0Context);
  const [state, setState] = useState({ errorMsg: '', isLoggingIn: false });

  const handleLogin = async ({ username, password }) => {
    setState({
      errorMsg: '',
      isLoggingIn: true,
    });
    loginWithCredentials(username, password).catch(err => {
      setState({
        errorMsg: err.description,
      });
    });
  };

  return (
    <EryDialog
      actions={
        <EryButtons justify='flex-end'>
          {!maximize && <EryButton onClick={onClose}>Cancel</EryButton>}
          {state.isLoggingIn && <EryLoadingSpinner />}
          <ErySubmitButton disabled={state.isLoggingIn}>Login</ErySubmitButton>
        </EryButtons>
      }
      isSubmitting={state.isLoggingIn}
      maximize={maximize}
      onClose={onClose}
      open={open}
      title='Login'
    >
      <EryForm contentMinWidth='50px' initialValues={{ username: '', password: '' }} onSubmit={handleLogin}>
        <Typography variant='body1'>
          {'By logging in, you are accepting the '}
          <a alt='Privacy policy' href='/privacy_policy'>
            Privacy Policy
          </a>
          {' & '}
          <a alt='Privacy policy' href='/terms_and_conditions'>
            Terms and Conditions
          </a>
          .
        </Typography>
        <Divider className={classes.divider} />
        <SocialMediaButtons
          disabled={state.isLoggingIn}
          setIsLoading={loading => setState(prevState => ({ ...prevState, isLoggingIn: loading }))}
        />
        <Divider className={classes.divider} />
        <EryFormFieldGroup direction='column' formik>
          <EryFormField label='Username' name='username' required>
            <EryInsetInput disabled={state.isLoggingIn} />
          </EryFormField>
          <EryFormField label='Password' name='password' required>
            <EryInsetInput disabled={state.isLoggingIn} type='password' />
          </EryFormField>
        </EryFormFieldGroup>
        {state.isLoggingIn ? <Typography color='error'>{state.errorMsg}</Typography> : null}
      </EryForm>
    </EryDialog>
  );
};

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  maximize: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(LoginDialog);
