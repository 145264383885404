import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import Typography from '@material-ui/core/Typography';

import EryButton from '../EryButton';
import EryButtons from '../EryButtons';

import { Auth0Context } from './index';

const SocialMediaButtons = ({ disabled, setIsLoading }) => {
  const { facebookLogin, googleLogin } = useContext(Auth0Context);

  const [state, setState] = useState({ errorMsg: '' });

  const handleClickFacebookLogin = () => {
    setIsLoading(true);
    facebookLogin()
      .catch(() => {
        setState({
          errorMsg: 'Unknown Error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickGoogleLogin = () => {
    setIsLoading(true);
    googleLogin()
      .catch(() => {
        setState({
          errorMsg: 'Unknown Error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /*
  const handleClickLinkedInLogin = () => {
    setIsLoading(true);
    linkedinLogin()
      .catch(() => {
        setState({
          errorMsg: 'Unknown Error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  */

  return (
    <React.Fragment>
      <EryButtons justifyContent='space-around'>
        <EryButton
          backgroundColor='#3b5998'
          borderColor='#3b5998'
          color='#fff'
          disabled={disabled}
          label='Using Facebook'
          onClick={handleClickFacebookLogin}
        />
        <EryButton
          backgroundColor='#db4437'
          borderColor='#db4437'
          color='#fff'
          disabled={disabled}
          label='Using Google'
          onClick={handleClickGoogleLogin}
        />
        {/*
        <EryButton
          backgroundColor='#0e76a8'
          borderColor='#0e76a8'
          color='#fff'
          disabled={disabled}
          label='Using LinkedIn'
          onClick={handleClickLinkedInLogin}
        />
        */}
      </EryButtons>
      {state.errorMsg && <Typography variant='error'>{state.errorMsg}</Typography>}
    </React.Fragment>
  );
};

SocialMediaButtons.propTypes = {
  disabled: PropTypes.bool,
  setIsLoading: PropTypes.bool,
};

export default SocialMediaButtons;
