import React from 'react';

import Layout, { Banner, Section, Paragraph } from 'ery-components/src/lib/EryLandingLayout';

import { Grid } from '@material-ui/core';

import auth0 from '../utils/auth0';
import BannerImg from '../images/banner.jpg';

import withRoot from '../withRoot';

const IndexPage = () => (
  <Layout auth0={auth0}>
    <Banner image={BannerImg}>sign up for the revolution!</Banner>
    <Grid container>
      <Grid item>
        <Section title='About'>
          <Paragraph>
            Behavery is a cloud-based platform for experiments and surveys in behavioral sciences and market research as well
            as for outsourcing microtasks. It can be used for psychological studies and decision-making experiments both in
            controlled lab-settings and for online studies. Real-time multi-participant experiments allows you to explore
            cooperative behavior, joint action, socio-economic games, and much more, as the experiments allow for interaction
            between concurrent participants.
          </Paragraph>
          <Paragraph>
            Behavery is an integrated system. Within the same platform, researchers implement and execute experiments and
            subjects sign up and participate in the experiments. Recorded participant data is reasily available for download at
            any time for the researchers.
          </Paragraph>
          <Paragraph>
            Behavery has an intuitive interface for implementing experiments, and is designed to be accessible to users without
            programming background. Use point-and-click to create surveys and to include images, video, and audio to show to
            the participants.
          </Paragraph>
        </Section>
      </Grid>
    </Grid>
  </Layout>
);

export default withRoot(IndexPage);
