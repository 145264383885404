import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import EryButton from '../EryButton';
import EryButtons from '../EryButtons';
import EryCheckbox from '../EryCheckbox';
import EryDialog from '../EryDialog';
import EryForm from '../EryForm';
import EryFormField from '../EryFormField';
import EryFormFieldGroup from '../EryFormFieldGroup';
import EryInsetInput from '../EryInsetInput';
import EryLoadingSpinner from '../EryLoadingSpinner';
import ErySubmitButton from '../ErySubmitButton';

import SocialMediaButtons from './SocialMediaButtons';
import { Auth0Context } from './index';

const styles = theme => ({
  divider: {
    margin: '2em',
  },
  signupHeader: {
    margin: '2em',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const SignupDialog = ({ classes, maximize, open, onClose, theme }) => {
  const { signupAndAuthorize } = useContext(Auth0Context);
  const [state, setState] = useState({ errorMsg: '', isSigningUp: false });

  const handleClickSignup = async ({ errors, name, email, username, password, code, agreeTos }, { setErrors }) => {
    setState({
      ...state,
      errorMsg: '',
      isSigningUp: true,
    });

    await signupAndAuthorize({
      connection: 'Username-Password-Authentication',
      email,
      password,
      user_metadata: {
        name,
        code,
        username,
      },
    })
      .catch(err => {
        if (typeof err.description === 'string') {
          if (err.description.startsWith('error in email - email format validation failed')) {
            setErrors({ ...errors, email: 'Invalid email' });
          } else {
            setState({
              ...state,
              errorMsg: err.description,
            });
          }
        } else if (err.name === 'PasswordStrengthError') {
          setErrors({ ...errors, password: err.policy });
        } else {
          setState({
            ...state,
            errorMsg: `Unknown error (${err.code})`,
          });
        }
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isSigningUp: false }));
      });
  };

  const handleClose = () => {
    setState({ ...state, errorMsg: '', isSigningUp: false });
    onClose();
  };

  return (
    <EryDialog maximize={maximize} onClose={onClose} open={open} title='Sign up'>
      <EryForm
        contentMinWidth='50px'
        initialValues={{
          name: '',
          email: '',
          username: '',
          password: '',
          password2: '',
          code: '',
          agreeUpdates: true,
          agreeTos: false,
        }}
        onSubmit={handleClickSignup}
        validate={values => {
          return values.password != values.password2 ? { password: 'Password not matching confirmation' } : {};
        }}
      >
        <Typography variant='body1'>
          {'By logging in, you are accepting the '}
          <a alt='Privacy policy' href='/privacy_policy'>
            Privacy Policy
          </a>
          {' & '}
          <a alt='Privacy policy' href='/terms_and_conditions'>
            Terms and Conditions
          </a>
          .
        </Typography>
        <Divider className={classes.divider} />
        <SocialMediaButtons
          disabled={state.isSigningUp}
          setIsLoading={loading => setState(prevState => ({ ...prevState, isSigningUp: loading }))}
        />
        <Divider className={classes.divider} />
        <EryFormFieldGroup direction='column' disabled={state.isSigningUp} formik>
          <EryFormField name='name' label='Full Name' required>
            <EryInsetInput />
          </EryFormField>
          <EryFormField name='email' label='Email' required>
            <EryInsetInput />
          </EryFormField>
          <EryFormField name='username' label='Username' required>
            <EryInsetInput />
          </EryFormField>
          <EryFormField name='password' label='Password' required>
            <EryInsetInput type='password' />
          </EryFormField>
          <EryFormField name='password2' label='Re-Enter Password'>
            <EryInsetInput type='password' />
          </EryFormField>
          <EryFormField name='code' label='Referral Code'>
            <EryInsetInput />
          </EryFormField>
          <EryFormField name='agreeUpdates'>
            <EryCheckbox checked label='Keep me updated with the newest news on Behavery' />
          </EryFormField>
          <EryFormField name='agreeTos' required>
            <EryCheckbox label='By checking this box you agree to the Terms and conditions' />
          </EryFormField>
          <Typography color='error'>{state.errorMsg}</Typography>
        </EryFormFieldGroup>
        <EryButtons justify='flex-end'>
          {!maximize && <EryButton onClick={handleClose}>Cancel</EryButton>}
          {state.isLoggingIn && <EryLoadingSpinner />}
          <ErySubmitButton disabled={state.isSigningUp} label='Sign up with Email' />
        </EryButtons>
      </EryForm>
    </EryDialog>
  );
};

SignupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  maximize: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SignupDialog);
