import React from 'react';
import _ from 'lodash';

import { Field, useFormikContext } from 'formik';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: props => props.margin || 0,
    marginBottom: props => props.marginBottom || '1.5rem',
    marginLeft: props => props.marginLeft || 0,
    marginTop: props => props.marginTop || 0,
    flexDirection: props =>
      props.eryWidth && props.eryWidth < (props.eryThresholdWidth || 500) ? 'column' : props.direction || 'row',
    alignItems: props =>
      props.eryWidth && props.eryWidth < (props.eryThresholdWidth || 500) ? 'flex-start' : props.alignItems || 'flex-start',
    opacity: props => (props.disabled ? 0.5 : 1),
    width: props => props.width || '100%',
    flex: props => props.flex,
    pointerEvents: props => (props.disabled ? 'none' : 'initial'),
  },
  element: {
    display: 'flex',
    flexDirection: props => props.contentDirection || 'column',
    alignItems: props => props.contentAlign || 'left',
    width: props => props.contentWidth || '100%',
    margin: props => props.contentMargin,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => props.labelJustifyContent || 'space-between',
    position: 'relative',
    transform: 'none',
    color: props => props.labelColor || theme.palette.text.primary,
    width: props => (props.labelWidth !== null && props.labelWidth !== undefined ? props.labelWidth : 120),
    height: props => props.labelHeight || 'initial',
    marginTop: props => props.labelMarginTop || '4px',
    marginBottom: props => (props.eryWidth && props.eryWidth < (props.eryThresholdWidth || 500) ? '0.5rem' : 0),
  },
  labelContainer: {
    display: props => props.labelDisplay || 'flex',
    alignItems: props => (props.direction === 'column' ? 'flex-start' : 'center'),
    marginBottom: props => (props.direction === 'column' ? props.spacing || '0.5rem' : 0),
    background: props => props.labelBackground || 'none',
  },
  separator: {
    fontWeight: 'bold',
    marginRight: props => props.spacing || 60,
    marginLeft: '0.5rem',
    marginTop: '4px',
  },
}));

const EryFormField = (props: Object) => {
  const classes = useStyles(props);

  const validate = value => {
    if (props.required && !value) {
      return 'Required';
    }
    if (props.validate) {
      return props.validate(value);
    }
  };

  return (
    <FormControl className={classes.root} error={props.error}>
      <div className={classes.labelContainer}>
        {props.label && <InputLabel className={classes.label}>{props.label}</InputLabel>}
        {(!props.eryWidth || (props.eryWidth && props.eryWidth >= (props.eryThresholdWidth || 500))) && props.separator && (
          <Typography className={classes.separator}>:</Typography>
        )}
      </div>
      <Box width='100%'>
        {props.formik ? (
          <Field name={props.name} validate={validate}>
            {({
              field, // { name, value, onChange, onBlur }
              form, //: { touched, errors, setFieldValue, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => {
              return (
                <div className={classes.element}>
                  {React.Children.toArray(props.children)
                    .filter(child => child)
                    .map(child =>
                      React.cloneElement(child, {
                        formik: props.formik,
                        ...field,
                        ...meta,
                        ...form,
                        ...child.props,
                        children: child.props.children || null,
                      })
                    )}
                  {_.get(form.errors, field.name) && <FormHelperText error>{_.get(form.errors, field.name)}</FormHelperText>}
                </div>
              );
            }}
          </Field>
        ) : (
          <div className={classes.element}>
            {React.Children.toArray(props.children)
              .filter(child => child)
              .map(child =>
                React.cloneElement(child, {
                  eryWidth: props.eryWidth,
                  eryHeight: props.eryHeight,
                  ...props,
                  ...child.props,
                })
              )}
          </div>
        )}
        {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      </Box>
    </FormControl>
  );
};

export default EryFormField;

export const TestEryFormField = EryFormField;
